import { ApolloClient, from, HttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import gql from 'graphql-tag'
import { User, UserAuthFragment } from '@types'
import {
  isAdmin,
  isModerator,
  isOwner,
  isPLM,
  isReleaseContributor,
} from '@utils/auth'
import { localStorageHandler } from '@utils/localStorageHandler'
import { FRONTEND_VERSION_HEADER } from './config'
import { isAppview } from '@utils/url'
import { CurrentUserShape } from '@core/contexts/CurrentUserContextV2'

export interface AuthData {
  userId: string | null
  creds: CredentialsOptions | null
  accessToken: string | null
  refreshToken: string | null
  isFetchingNewTokens: boolean
}

export enum LocalStorageKey {
  USER = 'user',
  PRE_AUTH_PATH = 'preAuthPath',
  STATE = 'state',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  TOKEN = 'token',
  FILTERS = 'filters',
  CODE_VERIFIER = 'codeVerifier',
}

export const LOCAL_STORAGE_AUTH_KEYS = [
  LocalStorageKey.ACCESS_TOKEN,
  LocalStorageKey.REFRESH_TOKEN,
  LocalStorageKey.USER,
]

const cache = new InMemoryCache()

const getHeaders = () => {
  return {
    [FRONTEND_VERSION_HEADER]: AppBuild,
  }
}

const createClient = () =>
  new ApolloClient({
    cache,
    link: from([
      setContext(() => ({
        headers: getHeaders(),
        credentials: 'include',
      })),
      new HttpLink({ uri: AppConfig.backendUrl }),
    ]),
  })

const apolloClient = createClient()

const authData: AuthData = {
  userId: null,
  creds: null,
  accessToken: null,
  refreshToken: null,
  isFetchingNewTokens: false,
}

const REDIRECT_URI = window.location.href

const redirectToAppviewLogin = () => {
  /* 
    This is for backwards compatibility with the UniFi Portal app, up until vx.y.z they are listening for this url to override the login page
  */

  const url = new URL('/oauth2/authorize', AppConfig.ssoUrl)
  url.searchParams.set('client_id', AppConfig.ssoClientId)
  url.searchParams.set('redirect', REDIRECT_URI)
  url.searchParams.set('redirect_uri', REDIRECT_URI)
  url.searchParams.set('app_native_view', 'sign_in')

  window.location.href = url.toString()
}

export const loginUser = (): void => {
  if (isAppview(window.location)) {
    redirectToAppviewLogin()

    return
  }

  const url = new URL('/login', AppConfig.accountUrl)
  url.searchParams.set('redirect', REDIRECT_URI)

  window.location.href = url.toString()
}

export const redirectToLogout = (): void => {
  clearStorage()
  const searchParams = new URLSearchParams({ redirect: REDIRECT_URI })
  window.location.href = `${
    AppConfig.accountUrl
  }/logout?${searchParams.toString()}`
}

export const redirectToSignUp = (): void => {
  const searchParams = new URLSearchParams({ redirect: REDIRECT_URI })

  if (isAppview(window.location)) {
    searchParams.set('app_native_view', 'sign_up')
  }

  window.location.href = `${
    AppConfig.accountUrl
  }/register?${searchParams.toString()}`
}

export const getAccessToken = (): string | null => {
  return authData.accessToken
}

export const clearStorage = (): void => {
  authData.accessToken = null
  authData.refreshToken = null
  authData.userId = null
  localStorageHandler.removeItem(LocalStorageKey.ACCESS_TOKEN)
  localStorageHandler.removeItem(LocalStorageKey.REFRESH_TOKEN)
  localStorageHandler.removeItem(LocalStorageKey.USER)
}

interface CredentialsOptions {
  expireTime: number
  accessKeyId: string
  secretAccessKey: string
  sessionToken?: string
}

const parseToCurrentUserContextShape = (
  data: UserAuthFragment
): CurrentUserShape => {
  const {
    id,
    slug,
    username,
    avatar,
    isEmployee,
    details,
    groups = null,
    canStartConversationWith,
    alphas,
    hasBetaAccess,
  } = data

  const user: CurrentUserShape = {
    id,
    data: {
      __typename: 'User',
      id,
      slug,
      username,
      avatar: {
        __typename: 'UserAvatar',
        color: avatar.color,
        content: avatar.content,
        image: avatar.image || null,
      },
      isEmployee,
      registeredAt: null,
      lastOnlineAt: null,
      tags: details ? details.tags : [],
      theme: details ? details.theme?.toLowerCase() || undefined : undefined,
      groups,

      // Just placeholder values for type system
      showOfficialBadge: false,
      canBeMentioned: false,
      canViewProfile: false,
      canStartConversationWith: false,
    },
    isAdmin: isAdmin(groups),
    isEmployee,
    isGuest: false,
    isModerator: isModerator(groups),
    isOwner: isOwner(groups),
    isPLM: isPLM(groups),
    isReleaseContributor: isReleaseContributor(groups),
    hasAlphaSupport: !!alphas?.length,
    hasBetaAccess: !!hasBetaAccess,
    isPermittedToStartConversation: canStartConversationWith,
    loginUser: () => null,
  }

  return user
}

const updateUserData = (data: UserAuthFragment): CurrentUserShape => {
  const user = parseToCurrentUserContextShape(data)

  authData.userId = user.id

  return user
}

const formatAuthError = (err: Error) => {
  const msg = err && err.message && err.message.replace('GraphQL error: ', '')
  return msg || 'Authentication error'
}

export const fetchUserSelf = async (): Promise<{
  user: CurrentUserShape | null
  error: string | null | unknown
}> => {
  /**
   * Try to fetch user self using sso cookie as authentication
   */

  try {
    const { errors, data } = await apolloClient.query<{
      userSelf: User | null
    }>({
      // TODO: use fragment?
      query: gql`
        query GetUserSelf {
          userSelf {
            id
            username
            title
            slug
            avatar {
              color
              content
              image
            }
            isEmployee
            groups
            hasBetaAccess
            alphas
            registeredAt
            lastOnlineAt
            showOfficialBadge
            canBeMentioned
            canViewProfile
            canStartConversationWith
            details {
              theme
            }
          }
        }
      `,
    })

    if (!data.userSelf) {
      return { user: null, error: null }
    }

    if (errors) {
      return { user: null, error: errors[0].message }
    }

    const user = updateUserData(data.userSelf)

    return { user, error: null }
  } catch (error) {
    if (error instanceof Error) {
      return { user: null, error: formatAuthError(error) }
    } else {
      return { user: null, error }
    }
  }
}
